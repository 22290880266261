.works_wrap {
  margin-top: 60px;
}

.bg-img{
  background-image:url('../../../public/static/images/Frame.png');
}

.works{
     text-align: center;
}
.works p{
  font-size: 16px;
  color: #708090;
  /* margin: 10px 0px 80px 0px; */
  line-height: 40px;
  padding-bottom: 55px;
  font-weight: 500;
}

.image-gallery {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
  }
  .am_et {
    display: flex;
    align-items: start;
  }
  .image-item {
    position: relative;
    padding: 35px;
    z-index: 2;
    margin: 0px auto;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    /* box-shadow: 0px 8px 24px -4px #18274B14; */
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
  }
  
  .image-item::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 2px;
    background-repeat: repeat-x;
  }
  
  .Line{
    position: absolute;
    top: 48px;
    left: 90px;
    z-index: 1;
  }
  
  .image_overlap{
 position: relative;
  }
   .image-item{
    background-color: white;
    z-index: 2;
    padding: 35px;
   }
  .text-line {
    display: flex;
    justify-content: space-between;
    margin:30px 0px 40px 0px;
  }
  
  .text-item {
    /* width: 20%; */
    padding: 10px;
    /* box-shadow: 0px 8px 24px -4px #18274B14; */
    /* z-index: 2; */
    /* text-align: center; */
  }
  
  .text-item h3 {
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 20px !important;
    margin-top: 20px;
  }
  
  .text-item p {
    color: #555;
    font-size: small;
  }
  
  .Borrow{
    font-weight: 800;
    margin: 56px 0;
    font-size: 30px;
  }
  .Borrow h4{ 
    font-size: 40px;
    font-weight: 700;
  }
  .list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 5px;
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .list-item {
    font-size: small;
    padding: 8px 0px;
  }
  .Data_list{
    text-align: center;
  }
  
  .list-header {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
.pamphlet-block{
  /* background-color:#1E90FF; */
  height: 350px;
  margin: 40px 0px ;
  background-image: url(../../../public/static/images/collateral.png);
  background-repeat: no-repeat;
  background-size: 100%;
}
  .pamphlet-wrap{
    padding-top: 100px;
    display: flex;
    justify-content: space-between;
  }
.pamphlet-left h2{
  color: #f2f2f2;
    /* line-height: 30px; */
    margin-bottom: 20px;
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
}
.pamphlet-left p{
  line-height: 28px;
    color: white;
    font-size: 14px;
    font-weight: 500;
}


.mar_ket {
  padding-bottom: 65px;
}


.Data_list {
  margin-top: 20px;
}
.Data_list table {
  width: 100%;
  border-collapse: collapse;
}
.Tbl-data th,
td {
  padding: 10px !important;
  text-align: center;
  font-size: 14px;
}
.Tbl-data th {
  background-color:#F9FAFF;
  color: #2B2B2B;
    font-size: 15px;
    font-weight: 600;
    height: 56px;
}
.cntnt_shrtnm {
  font-size: 12px;
  margin-left: 5px;
  color: #888;
} 