body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.common-srch{
  display: flex;
  align-items: center;
  width: 80%;
}
.cmn-btn{
  margin-left: 20px;
}
.cmn-btn button{
  font-size: 10px;
  padding: 8px 50px;
  margin-right: 20px !important;
  background: #F1F2F6;
  border-radius: 7px;
  border: 1px solid #F1F2F6;
  color: #000;
  font-size: 12px;
}

.cmn-btn button:hover,
.cmn-btn button:focus {
  color: #fff;
  background-color: #1E90FF;
  border: none;
}
.col-border{
  border-right: 1px solid #ccc;
}
.login-register a{
  text-decoration: none;
}

@media (max-width: 767px) {
  .cmn-btn button{
    margin-right: 0px !important;
  }
  .cmn-btn{
    margin-left: 0px;
  }
  .cmn-btn button{
    margin-left: 20px;
  }
  
}

@media (max-width: 600px) {
  .common-srch {
    flex-wrap: wrap;
    gap: 20px;
  }
}

@media (max-width: 480px) {
  .date-rnge{
    width: 100%;
  }
  .dropdown{
    width: 100% !important;
    margin-left: 0 !important;
  }
  .cmn-btn button{
    margin-left: 0px;
    width: 100% !important;
  }
  .cmn-btn{
    flex-wrap: wrap;
    gap: 20px;
  }
  .date-rnge div{
    width:  100% !important;
  }

}