/* .App {
  text-align: center;
} */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


* {
  padding: 0;
  margin: 0;
  outline: none !important;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

.container {
  max-width: 1170px;
  width: 90%;
  margin: 0 auto;
}

/* .Outer-div{
  margin: auto;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; 
} */

.card {
  width: 440px;
  background: white;
  border: 1px solid #F1F2F6;
  border-radius: 4px;
  padding: 20px;
  align-items: center;
  display: block;
  justify-content: center;
  margin: 200px auto;
  /* margin-top: 80px; */
}
.Outer-div2{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-new {
  width: 440px;
  background: white;
  border: 1px solid #F1F2F6;
  border-radius: 4px;
  padding: 20px;
  align-items: center;
  display: block;
  justify-content: center;
  margin: 120px auto;
}

.upload-identity-box {
  width: 100%;
  height: 169;
  border: 1px dashed #1E90FF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
}

.upload-identity-box div {
  width: 100%;
}

.homeloan-wrp {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dialog-container {
  position: absolute;
  bottom: 395px;
  right: 620px;
}

.progress-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.progress-bar {
  width: 100%;
  height: 3px;
  background-color: #ccc;
  /* border-radius: 5px; */
  margin: 55px 0px 10px;
}

.progress-bar-fill {
  height: 100%;
  background-color: #1E90FF;
  border-radius: 5px;
  transition: width 0.3s ease-in-out;
}

.progress-indicator {
  font-size: 14px;
  font-weight: 400;
  color: #708090;
  line-height: 17px;
}

.meta {
  background-color: white !important;
  color: black !important;
  border: 1px solid black !important;
  padding: 7px !important;
  margin-top: 30px !important;
  margin-bottom: 0 !important;
}



.header-metamask button {
  margin-right: 20px;
  display: flex;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #fff;
  color: black;
  font-size: 18px;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: #1E90FF;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input.deposit-radio {
  cursor: pointer;
}

.wrapcls {
  min-height: calc(100vh - 557px);
  padding-top: 120px;
}

.no_data {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  flex-direction: column;
}
.no_data figure {
  width: 140px;
  height: 140px;
}
.no_data img{
  width: 100%;
  height: 100%;
}
.no_data h3{
  font-size: 18px;
  color: #777;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter.MuiTableCell-sizeMedium.css-1yhpg23-MuiTableCell-root {
  border-right: 1px solid rgba(224, 224, 224, 1) !important;
}
table thead tr th{
  color: #708090 !important;
  font-weight: 700 !important;
}


.loader_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  left: 0%;
}
.loader_loading  figure {
  width: 70px;
  height: 70px;
}

.loader_loading img{
  width: 100%;
  height: 100%;
}
.latest table td{
  border-right: 1px solid rgba(224, 224, 224, 1) !important;
}


h6.err_msg {
  font-size: 14px;
  color: red;
  font-weight: 200;
  padding-top: 4px;
}

.cms h3 {
  /* text-align: center; */
  font-size: 33px;
  color: black;
}
.cms_center{
    text-align: center;
}

.ques_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main_div {
  padding: 10px 0px;
  cursor: pointer;
  border-bottom: 1px solid #969292;
}

.ques_txt {
  color: #2B2B2B;
  font-weight: 400;
}
.ques_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
  .ques_txt h5{
   
      font-size: 16px;
      padding-bottom: 0px;
    
  }

  .faq_ans h5{
      font-size: 15px;
      padding-top: 20px;
      line-height: 20px;
  }

  .cms p p {
    font-size: 16px;
    /* text-align: center; */
    padding-top: 13px;
}
.space {
  padding-top: 160px;
}

.menuitem{
  display: flex;
    align-items: center;
}
.menuitem figure{
  width: 30px;
  height: 30px;
}
.text h3{
  font-size: 19px;
  color: #2B2B2B;
  line-height: 22px;
  padding-bottom: 5px;
}

.text p{
  font-size: 15px;
  color: #708090;
}

/* div#basic-menu-login div {
  background: white !important;
  width: 320px !important;
} */

.menu1{
  margin-top: 30px;
}
.menu2 .MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper{
  padding: 20px;
  width: 300px;
}
.faq-main{
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* .loan-div button{
  background: red;
    padding: 10px;
    margin-right: 10px;
    border-radius: 3px;
    color: white;
} */

.text_field p {
  color: red !important;
  padding: 0px !important;
  padding-bottom: 20px !important;
  margin: 0px !important;
  text-align: left !important;
  line-height: 18px !important;
  font-size: 14px !important;
}

.emplyo{
  margin-top: 10px;
}
.input_fld{
  margin-top: 10px;
}

.text_field{
  margin-top: 10px !important;
}
.h3div{
  padding: 20px 0;
}
p.no_trans {
  position: absolute;
  margin: 30px 0;
  left: 40%;
  font-size: 14px;
  top: 80%;
  padding-top: 10px;
}

.reverted{
  color: #708090 !important;
  text-transform: uppercase;
  font-weight: bold !important;
  cursor: pointer !important;
}
.reverted:hover{
  color:#1E90FF !important;
}

.visible:hover{
  color:#1E90FF !important;
}
.upload_doc{
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tex_fld p{
  margin-left: 0 !important;
}

p.cms_text p span {
  font-size: 17px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}
*{
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}
.cms_text{
  margin-top: 20px;
  text-align: left ;
}
.cms_text h3 span{ 
    font-size: 28px !important;
}
.cms_text ul li{
  list-style: disc !important;
}

.cong{
  color: #1E90FF;
  text-align: center;
  font-size: 29px !important;
}

.cong_para{
  margin: 0 !important;
  padding-top: 10px;
  text-align: center;
  font-size: 18px;
}
 figure.check_img{
  width: 70px;
  height: 70px;
  margin: auto;
  margin-bottom: 14px !important;
}

.check_img img{
  width: 100%;
  height: 100%;
}
.deposit-table div{
  min-height: 200px !important; 
}

.no_emi{
  color: #708090 !important;
  text-transform: uppercase;
  font-weight: bold !important;
  cursor: not-allowed;
}